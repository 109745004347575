import { Dog } from "@/store";
import {
  ACQUISITION_TYPE,
  DOG_TRAINING_STATUS,
  DOG_TRAINING_STATUS_DISPLAY_TEXT,
} from "../../store/constants";

export function useGetDogStatus(item: Dog) {
  if (!item) {
    return "Not Applicable";
  }

  let dogStatus = "Acquired";
  if (item.acquisitionType) {
    dogStatus = ACQUISITION_TYPE.filter(
      (type) => type.value === item.acquisitionType
    ).map((type) => type.text)[0];
  }

  if (item.dogTrainings?.length) {
    dogStatus = DOG_TRAINING_STATUS_DISPLAY_TEXT.IN_TRAINING;
  }
  if (item.trainingStatus) {
    dogStatus = DOG_TRAINING_STATUS.filter(
      (status) => status.value === item.trainingStatus
    ).map((status) => status.text)[0];
  }
  if (item.adoptionDate || item.placementDate) {
    dogStatus = item.adoptionDate
      ? "Placed by Adoption"
      : "Placed with Recipient";
  }

  if ((dogStatus || "").toLowerCase() === "passed") {
    dogStatus = DOG_TRAINING_STATUS_DISPLAY_TEXT.PASSED;
  }

  if ((dogStatus || "").toLowerCase() === "failed") {
    dogStatus = DOG_TRAINING_STATUS_DISPLAY_TEXT.FAILED;
  }

  if (item.retired) {
    dogStatus = "Retired";
  }

  if (item.deceased) {
    dogStatus = "Deceased";
  }

  if (!dogStatus) dogStatus = "Acquired";

  return dogStatus;
}
