import { DogTraining } from "@/store";

export function useGetDogTrainingTime(item: DogTraining[]) {
  function getPublicMinutes(item: DogTraining[]) {
    let totalTime = 0;
    let trainingTimes = [];
    trainingTimes = item.map((training) =>
      parseInt(training.publicAccessTraining as string)
    );
    if (trainingTimes.length) {
      totalTime = trainingTimes.reduce(
        (acc: number, time: number) => acc + time
      );
    }
    return totalTime;
  }

  function getRegularMinutes(item: DogTraining[]) {
    let totalTime = 0;
    let trainingTimes = [];
    trainingTimes = item.map((training) =>
      parseInt(training.regularTraining as string)
    );
    if (trainingTimes.length) {
      totalTime = trainingTimes.reduce(
        (acc: number, time: number) => acc + time
      );
    }
    return totalTime;
  }

  const totalPublicMinutes = getPublicMinutes(item);
  const totalRegularMinutes = getRegularMinutes(item);

  return { totalPublicMinutes, totalRegularMinutes };
}
