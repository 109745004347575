import { Dog } from "@/store";
import {
  DOG_TRAINING_STATUS,
  DOG_TRAINING_STATUS_DISPLAY_TEXT,
} from "@/store/constants";

export function useGetDogTrainingStatus(item: Dog) {
  let trainingStatus = "No status set";

  if (item?.dogTrainings?.length) {
    trainingStatus = DOG_TRAINING_STATUS_DISPLAY_TEXT.IN_TRAINING;
  }

  if (item.trainingStatus) {
    switch (
      DOG_TRAINING_STATUS.filter(
        (status) => status.value === item.trainingStatus
      ).map((status) => status.text.toLowerCase())[0]
    ) {
      case "passed":
        trainingStatus = DOG_TRAINING_STATUS_DISPLAY_TEXT.PASSED;
        break;
      case "failed":
        trainingStatus = DOG_TRAINING_STATUS_DISPLAY_TEXT.FAILED;
        break;
    }
  }

  return trainingStatus;
}
