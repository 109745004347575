





















































































































































































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  onBeforeMount,
  ref,
  watchEffect,
} from "@vue/composition-api";
import { Dog, DogFile, DogNote, User } from "@/store";
import {
  useStore,
  useDogService,
  useRouter,
  useApplicantService,
  useUserService,
} from "@/composables/providers";
import moment from "moment";
import { DogActions, DogGetters } from "@/store/dog";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";
import DogTrainingsTable from "@/components/tables/DogTraining.vue";
import { ApplicantActions } from "@/store/applicant";
import { UserActions, UserGetters } from "@/store/user";
import FileDialog from "@/components/dialogs/File.vue";
import { ACQUISITION_TYPE, DOG_TRAINING_STATUS } from "@/store/constants";
import { useGetDogStatus } from "@/composables/helpers/use-get-dog-status";
import { useGetDogTrainingStatus } from "@/composables/helpers/use-get-dog-training-status";
import { useGetDogTrainingTime } from "@/composables/helpers";

export default defineComponent({
  name: "DogDetail",
  components: {
    ConfirmationDialog,
    DogTrainingsTable,
    FileDialog,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const service = useDogService();
    const userService = useUserService();
    const applicantService = useApplicantService();
    const id = computed<string>(() => router.currentRoute.params["id"] || "");
    const vetPhone = ref<string>("");

    const loading = computed<boolean>(() => store.getters[DogGetters.LOADING]);

    const selected = computed<Dog | undefined>(
      () => store.getters[DogGetters.SELECTED]
    );

    const isDefaultPhoto = computed<boolean>(() => {
      return !(selected.value || {}).displayPhoto;
    });

    const photoSource = computed<string | undefined>(() => {
      if ((selected.value || {}).displayPhoto) {
        return (selected.value || {}).displayPhoto;
      } else {
        return `${require("@/assets/placeholder.png")}`;
      }
    });

    const action = computed<string>(() =>
      selected.value && selected.value.deleted ? "Reactivate" : "Delete"
    );

    const adoptionFile = computed<DogFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "adoptionFile"
      )
    );

    const medicalNotesFiles = computed<DogFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "medicalNotesFile"
      )
    );

    const medicalRecordsFiles = computed<DogFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "medicalRecordsFile"
      )
    );

    const acquisitionFiles = computed<DogFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "acquisitionFile"
      )
    );

    const users = computed<User[]>(() => store.getters[UserGetters.USERS]);
    const notes = computed<DogNote[] | undefined>(() => {
      const notes = (((selected.value || {}).notes as DogNote[]) || []).sort(
        (a: DogNote, b: DogNote) => {
          const dateA = new Date(a.createDate).getTime();
          const dateB = new Date(b.createDate).getTime();

          if (dateA > dateB) {
            return 1;
          }
          if (dateB > dateA) {
            return -1;
          }
          return 0;
        }
      );
      return notes;
    });

    onBeforeMount(async () => {
      await Promise.all([
        store.dispatch(DogActions.SELECT, {
          id: id.value,
          service: service,
        }),
        store.dispatch(UserActions.LIST, {
          service: userService,
        }),
      ]);
    });

    async function deleteDog() {
      await store.dispatch(DogActions.DELETE, {
        service: service,
        id: id.value,
      });
      router.push({
        name: "DogList",
      });
    }

    function navigateToList() {
      router.push({
        name: "DogList",
      });
    }

    async function editDog() {
      await store.dispatch(DogActions.SELECT, {
        id: id.value,
        service,
      });
      await router.push({
        name: "DogUpdate",
        params: {
          id: ((selected.value || {}).id || "").toString(),
        },
      });
    }

    async function reactivateDog() {
      await store.dispatch(DogActions.REACTIVATE, {
        service,
        id: id.value,
      });
      router.push({
        name: "DogList",
      });
    }

    function getDate(date: string) {
      if (date) {
        return moment(new Date(date).toISOString().substr(0, 10)).format(
          "MM/DD/YYYY"
        );
      }
    }

    function getTrainingHours(trainingTime: string) {
      const time = parseInt(trainingTime);
      if (time <= 0 || isNaN(time)) {
        return "0:00";
      }
      const hours = Math.floor(time / 60).toString();
      let minutes = (time % 60).toString();
      minutes = parseInt(minutes) < 10 ? `0${minutes}` : minutes.toString();
      return hours + ":" + minutes;
    }

    function getTrainingStatus() {
      if (!selected.value) return "";
      const statusCode = selected.value.trainingStatus;
      if (statusCode) {
        return (
          DOG_TRAINING_STATUS.find((status) => status.value === statusCode) ||
          {}
        ).text;
      } else if (selected.value.dogTrainings?.length) {
        return "In Training";
      }
    }

    function getTotalPublicHours() {
      if (selected.value?.dogTrainings)
        return useGetDogTrainingTime(selected.value.dogTrainings)
          .totalPublicMinutes;
      else return 0;
    }

    function getTotalRegularHours() {
      if (selected.value?.dogTrainings)
        return useGetDogTrainingTime(selected.value.dogTrainings)
          .totalRegularMinutes;
      else return 0;
    }

    function getPlacementOrAdoptionDate() {
      if (selected.value) {
        if (selected.value.adoptionDate) {
          return getDate(selected.value.adoptionDate as string);
        } else if (selected.value.placementDate) {
          return getDate(selected.value.placementDate as string);
        } else return "Not Applicable";
      }
    }

    async function navigateToApplicant() {
      if (selected.value && selected.value.applicant) {
        await store.dispatch(ApplicantActions.SELECT, {
          id: selected.value.applicant.id,
          service: applicantService,
        });
        await router.push({
          name: "ApplicantDetail",
          params: {
            id: (selected.value.applicant.id || "").toString(),
          },
        });
      }
    }

    function getFormattedPhone(phone: string) {
      const areaCode = phone.slice(2, 5);
      const prefix = phone.slice(5, 8);
      const line = phone.slice(8, 13);
      return `(${areaCode}) ${prefix}-${line}`;
    }

    watchEffect(() => {
      vetPhone.value = ((selected.value || {}).veterinarian || {}).phone
        ? getFormattedPhone(
            ((selected.value || {}).veterinarian || {}).phone as string
          )
        : "Not Applicable";
    });

    function getNoteLabel(note: DogNote) {
      const author = users.value.filter(
        (user) => user.id === note.createdBy
      )[0];

      if (!note.updatedBy) {
        return `Author: ${(author || {}).firstName} ${
          (author || {}).lastName
        }---Created: ${getDate(note.createDate.toString())}`;
      } else {
        const editor = users.value.filter(
          (user) => user.id === note.updatedBy
        )[0];
        if (note.updatedBy === note.createdBy) {
          return `Author: ${(author || {}).firstName}---Created: ${getDate(
            note.createDate.toString()
          )}---Edited: ${getDate(note.updateDate.toString())} `;
        } else {
          return `Author: ${(author || {}).firstName} ${
            (author || {}).lastName
          }---Created: ${getDate(note.createDate.toString())}---Edited by: ${
            (editor || {}).firstName
          } ${(editor || {}).lastName}---${getDate(
            note.updateDate.toString()
          )}`;
        }
      }
    }

    function getTransformedAcquisition() {
      if ((selected.value || {}).acquisitionType) {
        return ACQUISITION_TYPE.filter(
          (type) => type.value === (selected.value || {}).acquisitionType
        ).map((type) => type.text)[0];
      } else return "Not Applicable";
    }

    const publicHours = computed<string>(() => {
      return (
        getTrainingHours((getTotalPublicHours() || "").toString()) || "0:00"
      );
    });

    const regularHours = computed<string>(() => {
      return (
        getTrainingHours((getTotalRegularHours() || "").toString()) || "0:00"
      );
    });

    const totalHours = computed<string | undefined>(() => {
      return getTrainingHours(
        (
          (getTotalRegularHours() || 0) + (getTotalPublicHours() || 0)
        ).toString() || "0:00"
      );
    });

    const getPlacementStatus = (): string => {
      if (selected.value) {
        if (selected.value.adoptionDate) {
          return "Adopted";
        } else if (selected.value.placementDate) {
          return "Placed with Recipient";
        } else if (selected.value.retired) {
          return "Retired";
        } else if (selected.value.deceased) {
          return "Deceased";
        }
      }
      return "Not Applicable";
    };

    return {
      store,
      loading,
      router,
      id,
      isDefaultPhoto,
      selected,
      getDate,
      photoSource,
      editDog,
      deleteDog,
      navigateToList,
      action,
      reactivateDog,
      getTrainingStatus,
      getPlacementOrAdoptionDate,
      navigateToApplicant,
      publicHours,
      regularHours,
      totalHours,
      notes,
      getNoteLabel,
      vetPhone,
      adoptionFile,
      acquisitionFiles,
      getTransformedAcquisition,
      medicalNotesFiles,
      medicalRecordsFiles,
      useGetDogStatus,
      useGetDogTrainingStatus,
      getPlacementStatus,
    };
  },
});
