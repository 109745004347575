import { ApplicantReport } from "@/store/interfaces";
import { DogReport } from "@/store/interfaces";

export function useGenerateReport(
  type: string,
  data: ApplicantReport[] | DogReport[]
) {
  let report = "";
  if (type === "applicant") {
    report =
      "First Name, Last Name, Birth Date, State, Status, Start Date, Home Visit Date, Military, First Responder, Dog, Created";
  } else if (type === "dog") {
    report =
      "Name, Birth Date, Date Acquired, Status, Public Training Hours, Regular Training Hours, Placement Date, Applicant, Recertification Date, Created";
  }

  const columnDelimiter = ",";
  const lineDelimiter = "\n";

  if (!(data || []).length) {
    return null;
  }

  const keys = Object.keys(data[0]);

  report += lineDelimiter;
  if (data && type === "applicant") {
    (data as ApplicantReport[]).forEach((item: ApplicantReport) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) {
          report += columnDelimiter;
        }

        if (!item[key as keyof ApplicantReport])
          item[key as keyof ApplicantReport] = "Not Applicable";
        report +=
          typeof item[key as keyof ApplicantReport] === "string" &&
          (item[key as keyof ApplicantReport] as string).includes(
            columnDelimiter
          )
            ? `"${item[key as keyof ApplicantReport]}"`
            : item[key as keyof ApplicantReport];

        ctr++;
      });
      report += lineDelimiter;
    });
  } else if (data && type === "dog") {
    (data as DogReport[]).forEach((item: DogReport) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) {
          report += columnDelimiter;
        }

        if (!item[key as keyof DogReport])
          item[key as keyof DogReport] = "Not Applicable";
        report +=
          typeof item[key as keyof DogReport] === "string" &&
          (item[key as keyof DogReport] as string).includes(columnDelimiter)
            ? `"${item[key as keyof DogReport]}"`
            : item[key as keyof DogReport];

        ctr++;
      });
      report += lineDelimiter;
    });
  }

  return report;
}
